import dynamic from 'next/dynamic'

import {
  SanityBlogAuthorPostGridModule,
  SanityBlogCategoryPostGridModule,
  SanityBlogPostGridModule,
  SanityBlogPostHeaderModule,
  SanityCartFormModule,
  SanityCollectionGridModule,
  SanityDividerPhotoModule,
  SanityGridModule,
  SanityHeroModule,
  SanityMarqueeModule,
  SanityModule,
  SanityModuleType,
  SanityReviewWidgetModule,
  SanityVideoModule,
  SanityProductHeroModule,
} from '@data/sanity/queries/types/modules'
import { Reviews } from '@lib/review'

const BlogPostGrid = dynamic(() => import('./blog/blog-post-grid'))
const BlogPostHeader = dynamic(() => import('./blog/blog-post-header'))
const DividerPhoto = dynamic(() => import('./divider-photo'))
const Grid = dynamic(() => import('./grid'))
const Hero = dynamic(() => import('./hero'))
const Marquee = dynamic(() => import('./marquee'))
const ReviewWidget = dynamic(() => import('./review-widget'))
const CartForm = dynamic(() => import('./shop/cart/form'))
const Collection = dynamic(() => import('./shop/collection'))
const ProductHero = dynamic(() => import('./shop/product-hero'))
const VideoModule = dynamic(() => import('./video'))

interface ModuleProps {
  module: SanityModule
  reviews?: Reviews
}

const Module = ({ module, reviews }: ModuleProps) => {
  switch (module._type as SanityModuleType) {
    case SanityModuleType.GRID: {
      const { columns, size, style, noColumnGaps, noRowGaps, reverseSequence } =
        module as SanityGridModule

      return (
        <Grid
          columns={columns}
          size={size}
          style={style}
          noColumnGaps={noColumnGaps}
          noRowGaps={noRowGaps}
          reverseSequence={reverseSequence}
        />
      )
    }

    case SanityModuleType.HERO: {
      const { bgType, contentPosition, content, photos, muxVideo, vimeoVideo } =
        module as SanityHeroModule

      return (
        <Hero
          bgType={bgType}
          contentPosition={contentPosition}
          content={content}
          photos={photos}
          muxVideo={muxVideo}
          vimeoVideo={vimeoVideo}
        />
      )
    }

    case SanityModuleType.DIVIDER_PHOTO: {
      const { photo } = module as SanityDividerPhotoModule

      return <DividerPhoto photo={photo} />
    }

    case SanityModuleType.REVIEW_WIDGET: {
      const { type, reviewsStrings } = module as SanityReviewWidgetModule

      if (!reviews) {
        return null
      }

      return (
        <ReviewWidget
          type={type}
          reviewsStrings={reviewsStrings}
          reviews={reviews}
        />
      )
    }

    case SanityModuleType.PRODUCT_HERO: {
      const { active, product } = module as SanityProductHeroModule

      if (!active || !product) {
        return null
      }

      return <ProductHero product={product} />
    }

    case SanityModuleType.COLLECTION_GRID: {
      const { active, collection, shop, cartSettings, collectionStrings } =
        module as SanityCollectionGridModule

      if (!active) {
        return null
      }

      if (shop?.products && shop.products.length > 0) {
        return (
          <Collection
            collectionStrings={collectionStrings}
            products={shop.products}
            featuredProductIds={shop.featuredProductIds ?? []}
            paginationLimit={cartSettings?.paginationLimit ?? 10}
            sort={cartSettings?.sort}
            filter={cartSettings?.filter}
          />
        )
      }

      if (collection?.products && collection.products.length > 0) {
        return (
          <Collection
            collectionStrings={collectionStrings}
            products={collection.products}
            featuredProductIds={collection.featuredProductIds ?? []}
            paginationLimit={cartSettings?.paginationLimit ?? 10}
            title={collection.title}
            sort={cartSettings?.sort}
            filter={cartSettings?.filter}
          />
        )
      }

      return null
    }

    case SanityModuleType.CART_FORM: {
      const { active, cartSettings } = module as SanityCartFormModule

      if (!active || !cartSettings) {
        return null
      }

      return <CartForm cartSettings={cartSettings} />
    }

    case SanityModuleType.VIDEO: {
      const { type, vimeoVideo, muxVideo, settings, aspectRatio } =
        module as SanityVideoModule

      return (
        <VideoModule
          type={type}
          vimeoVideo={vimeoVideo}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
        />
      )
    }

    case SanityModuleType.MARQUEE: {
      const { items, speed, reverse, pausable } = module as SanityMarqueeModule

      return (
        <Marquee
          items={items}
          speed={speed ?? 0.5}
          reverse={reverse}
          pausable={pausable}
        />
      )
    }

    case SanityModuleType.BLOG_POST_GRID: {
      const { posts, options } = module as SanityBlogPostGridModule

      return <BlogPostGrid posts={posts} options={options} />
    }

    case SanityModuleType.BLOG_POST_HEADER: {
      const { post, options } = module as SanityBlogPostHeaderModule

      if (!post) {
        return null
      }

      return <BlogPostHeader post={post} options={options} />
    }

    case SanityModuleType.BLOG_CATEGORY_POST_GRID: {
      const { posts, options } = module as SanityBlogCategoryPostGridModule

      return <BlogPostGrid posts={posts} options={options} />
    }

    case SanityModuleType.BLOG_AUTHOR_POST_GRID: {
      const { posts, options } = module as SanityBlogAuthorPostGridModule

      return <BlogPostGrid posts={posts} options={options} />
    }
  }
}

export default Module
