import groq from 'groq'

import { blogPostWithoutBodyFragment, blogPostOptionsFragment } from './blog'
import { complexContentFragment, simpleContentFragment } from './content'
import { imageFragment } from './image'
import { productFragment } from './product'
import { newsletterFragment } from './site'
import { videoFragment } from './video'

const freeformBlockFragment = groq`
  textAlign,
  maxWidth,
  content[] {
    ${complexContentFragment}
  },
`

const accordionsBlockFragment = groq`
  items[] {
    title,
    "id": _key,
    content[] {
      ${simpleContentFragment}
    },
  },
`

const productCardBlockFragment = groq`
  product->{
    ${productFragment}
  },
`

const authStringsFragment = groq`
  signupFirstName,
  signupFirstNamePlaceholder,
  signupFirstNameMissing,
  signupLastName,
  signupLastNamePlaceholder,
  signupLastNameMissing,
  signupEmail,
  signupEmailPlaceholder,
  signupEmailMissing,
  signupPassword,
  signupPasswordPlaceholder,
  signupPasswordMissing,
  signupErrorMessage[] {
    ${complexContentFragment}
  },
  signupSubmit,
  loginErrorMessage[] {
    ${complexContentFragment}
  },
  loginFailedMessage,
  loginSubmit,
  passwordRecoveryErrorMessage[] {
    ${complexContentFragment}
  },
  passwordRecoverySuccessMessage[] {
    ${complexContentFragment}
  },
  passwordRecoverySubmit,
`

const signupFormBlockFragment = groq`
  "authStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${authStringsFragment}
  },
  active,
`

const loginFormBlockFragment = groq`
  "authStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${authStringsFragment}
  },
  active,
`

const passwordRecoveryFormBlockFragment = groq`
  "authStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${authStringsFragment}
  },
  active,
`

const accountStringsFragment = groq`
  account,
  accountAccountDetailsHeading,
  accountViewAddresses,
  accountViewSubscriptions,
  accountDefaultAddressLabel,
  accountAddAddressHeading,
  accountEditAddressHeading,
  accountFirstName,
  accountFirstNamePlaceholder,
  accountFirstNameMissing,
  accountLastName,
  accountLastNamePlaceholder,
  accountLastNameMissing,
  accountCompany,
  accountCompanyPlaceholder,
  accountCompanyMissing,
  accountAddressLine1,
  accountAddressLine1Placeholder,
  accountAddressLine1Missing,
  accountAddressLine2,
  accountAddressLine2Placeholder,
  accountCity,
  accountCityPlaceholder,
  accountCityMissing,
  accountCountry,
  accountCountryPlaceholder,
  accountCountryMissing,
  accountZip,
  accountZipPlaceholder,
  accountZipMissing,
  accountPhone,
  accountPhonePlaceholder,
  accountPhoneMissing,
  accountSetAsDefault,
  addressErrorMessage,
  accountAddAddress,
  accountEditAddress,
  accountProductListHeading,
  accountProductListProduct,
  accountProductListViewProduct,
  accountOrderListHeading,
  accountOrderListOrder,
  accountOrderListDate,
  accountOrderListPaymentStatus,
  accountOrderListFulfillmentStatus,
  accountOrderListTotal,
  accountOrderDetailsHeading,
  accountOrderDetailsDate,
  accountOrderDetailsProduct,
  accountOrderDetailsSku,
  accountOrderDetailsPrice,
  accountOrderDetailsQuantity,
  accountOrderDetailsTotal,
  accountOrderDetailsSubtotal,
  accountOrderDetailsShipping,
  accountOrderDetailsTax,
  accountOrderDetailsBillingAddress,
  accountOrderDetailsShippingAddress,
  accountOrderListPaymentStatusAuthorized,
  accountOrderListPaymentStatusPaid,
  accountOrderListPaymentStatusPartiallyPaid,
  accountOrderListPaymentStatusPartiallyRefunded,
  accountOrderListPaymentStatusPending,
  accountOrderListPaymentStatusRefunded,
  accountOrderListPaymentStatusVoided,
  accountOrderListFulfillmentStatusFulfilled,
  accountOrderListFulfillmentStatusInProgress,
  accountOrderListFulfillmentStatusOnHold,
  accountOrderListFulfillmentStatusOpen,
  accountOrderListFulfillmentStatusPartiallyFulfilled,
  accountOrderListFulfillmentStatusPendingFulfillment,
  accountOrderListFulfillmentStatusRestocked,
  accountOrderListFulfillmentStatusScheduled,
  accountOrderListFulfillmentStatusUnfulfilled,
  accountProductListEmpty,
  accountOrderListEmpty,
`

const accountDetailsBlockFragment = groq`
  "accountStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountStringsFragment}
  },
  active,
`

const accountAddressDetailsBlockFragment = groq`
  "accountStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountStringsFragment}
  },
  active,
`

const accountProductListBlockFragment = groq`
  "accountStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountStringsFragment}
  },
  active,
`

const accountOrderListBlockFragment = groq`
  "accountStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountStringsFragment}
  },
  active,
`

const collectionCarouselBlockFragment = groq`
  collection->{
    products[]->{
      ${productFragment}
    },
  },
`

const productBundleFormBlockFragment = groq`
  productBundle->{
    name,
    slots[] {
      name,
      "selectedOptionNames": products[] {
        "productId": selection.id,
        "list": selection.options[selected == true].name,
      },
      "products": *[
        _type == "product" &&
        _id in ^.products[].selection.id &&
        locale == $locale &&
        !wasDeleted
      ] {
        ${productFragment}
      },
      showSelection,
      label,
    },
    variantMap[] {
      products[] {
        "id": selection.id,
        "variantIds": selection.variants[selected == true].id,
      },
    },
    gallery[] {
      variantCombination,
      photos[] {
        ${imageFragment}
      },
    },
  },
  showGallery,
`

const blogPostCardBlockFragment = groq`
  post->{
    ${blogPostWithoutBodyFragment}
  },
  options {
    ${blogPostOptionsFragment}
  },
`

export const blocksFragment = groq`
  _key,
  _type,
  _type == 'freeform' => {
    ${freeformBlockFragment}
  },
  _type == 'accordions' => {
    ${accordionsBlockFragment}
  },
  _type == 'productCard' => {
    ${productCardBlockFragment}
  },
  _type == 'signupForm' => {
    ${signupFormBlockFragment}
  },
  _type == 'loginForm' => {
    ${loginFormBlockFragment}
  },
  _type == 'passwordRecoveryForm' => {
    ${passwordRecoveryFormBlockFragment}
  },
  _type == 'accountDetails' => {
    ${accountDetailsBlockFragment}
  },
  _type == 'accountAddressDetails' => {
    ${accountAddressDetailsBlockFragment}
  },
  _type == 'accountProductList' => {
    ${accountProductListBlockFragment}
  },
  _type == 'accountOrderList' => {
    ${accountOrderListBlockFragment}
  },
  _type == 'video' => {
    ${videoFragment}
  },
  _type == 'collectionCarousel' => {
    ${collectionCarouselBlockFragment}
  },
  _type == 'newsletter' => {
    ${newsletterFragment}
  },
  _type == 'productBundleForm' => {
    ${productBundleFormBlockFragment}
  },
  _type == 'blogPostCard' => {
    ${blogPostCardBlockFragment}
  },
 `
