import { SanityModule } from '@data/sanity/queries/types/modules'
import { Reviews } from '@lib/review'

import Module from './module'

interface ModulesProps {
  modules: SanityModule[] | null
  reviews?: Reviews
}

const Modules = ({ modules, reviews }: ModulesProps) => {
  if (!modules) {
    return null
  }

  return (
    <>
      {modules.map((module) => (
        <Module key={module._key} module={module} reviews={reviews} />
      ))}
    </>
  )
}

export default Modules
