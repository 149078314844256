import groq from 'groq'

import { blogSettingsFragment } from './blog'
import { cartFragment } from './cart'
import { complexContentFragment, simpleContentFragment } from './content'
import { imageFragment } from './image'
import { linkFragment, linkPageFragment } from './link'
import { productFragment } from './product'

const headerDesktopMenuFragment = groq`
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
    featured[]->{
      ${productFragment}
    },
  },
`

const headerMobileMenuFragment = groq`
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
  },
`

const headerPromoFragment = groq`
  enabled,
  display,
  text,
  "link": link->{
    ${linkPageFragment}
  },
`

const headerFragment = groq`
  menuDesktopLeft->{
    ${headerDesktopMenuFragment}
  },
  menuDesktopRight->{
    ${headerDesktopMenuFragment}
  },
  menuMobilePrimary->{
    ${headerMobileMenuFragment}
  },
  menuMobileSecondary->{
    ${headerMobileMenuFragment}
  },
  "promo": *[
    _type == "promoSettings" &&
    locale == $locale
  ][0] {
    ${headerPromoFragment}
  },
`

export const newsletterFragment = groq`
  klaviyoListID,
  submit,
  successMsg[] {
    ${complexContentFragment}
  },
  errorMsg[] {
    ${complexContentFragment}
  },
  terms[] {
    ${simpleContentFragment}
  },
`

const footerBlock1Fragment = groq`
  "title": blockTitle1,
  "description": blockDescription1[] {
    ${simpleContentFragment}
  },
  newsletter {
    ${newsletterFragment}
  },
`

const footerBlock2Fragment = groq`
  "title": blockTitle2,
  "menu": blockMenu2->{
    items[] {
      ${linkFragment}
    }
  },
`

const footerBlock3Fragment = groq`
  "title": blockTitle3,
  "menu": blockMenu3->{
    items[] {
      ${linkFragment}
    }
  },
`

const footerBlock4Fragment = groq`
  "title": blockTitle4,
  "description": blockDescription4[] {
    ${simpleContentFragment}
  },
  social[] {
    _key,
    icon,
    url
  },
`

const footerFragment = groq`
  "blocks": [
    {
      ${footerBlock1Fragment}
    },
    {
      ${footerBlock2Fragment}
    },
    {
      ${footerBlock3Fragment}
    },
    {
      ${footerBlock4Fragment}
    }
  ],
  copyright,
  paymentMethods[] {
    ${imageFragment}
  },
`

const productCountsFragment = groq`
  "slug": slug.current,
  "count": count(products),
`

const cookieConsentFragment = groq`
  enabled,
  message,
  "link": link->{
    ${linkPageFragment}
  },
`

const seoFragment = groq`
  siteTitle,
  metaTitle,
  metaDesc,
  shareTitle,
  shareDesc,
  shareGraphic,
`

const siteStringsFragment = groq`
  loadingPageTitle,
  languageSwitch,
  cartLink,
  featuredProducts,
  skipToContent,
  goHomeLabel,
  mobileMenuLabel,
  productLowStock,
  productOutOfStock,
  productPriceLabel,
  productColorOptionLabel,
  productDiscountText,
  productIncreaseQuantity,
  productDecreaseQuantity,
  productEnterQuantity,
  cartTitle,
  cartClose,
  cartEmpty,
  cartVatSectionTitle,
  cartVatIdInputPlaceholder,
  cartVatIdError,
  cartInvalidVatIdError,
  cartCommentSectionTitle,
  cartCommentInputPlaceholder,
  cartDiscount,
  cartProductDiscount,
  cartSubtotal,
  cartTotal,
  cartSubmit,
  cartAddToCartErrorMessage[] {
    ${complexContentFragment}
  },
  carouselCounterText,
  carouselLeftArrowLabel,
  carouselRightArrowLabel,
  carouselDotLabel,
  buttonAddToCart,
  buttonRemove,
  buttonAccept,
  buttonLearnMore,
  buttonTryAgain,
  buttonLoadMore,
  buttonUnavailable,
  buttonWaiting,
  buttonLoading,
  buttonAdding,
  buttonUpdating,
  buttonSubmitting,
  buttonEdit,
  buttonDelete,
  buttonCancel,
  emailAddress,
  emailMissing,
  emailInvalid,
  productWaitlistSubmit,
  productWaitlistSuccess,
  productWaitlistError,
`

const identityFragment = groq`
  logo {
    ${imageFragment}
  },
  invertedLogo {
    ${imageFragment}
  },
`

const publicSettingsFragment = groq`
  siteURL,
  gtmContainerId,
  analyticsId,
  gtagId,
  facebookEvents,
  facebookPixelId,
  facebookDomainVerification,
  facebookTestEventCode,
  stampedApiKey,
  partnerAdsTracking,
  pageAnimation,
`

const allSettingsFragment = groq`
  klaviyoPrivateKey,
  stampedStoreHash,
  ${publicSettingsFragment}
`

const discountFragment = groq`
  _id,
  type,
  title,
  discountValuePercent,
  ...select(type == 'quantity' => {
    minimumQuantity,
    maximumQuantity,
  }),
  ...select(type == 'bundle' => {
    products[] {
      "id": selection.id,
      "variantIds": selection.variants[selected == true].id,
    },
    doNotStackWithQuantityDiscounts,
  }),
`

export const publicSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  }
`

export const allSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${allSettingsFragment}
  }
`

export const discountQuery = groq`
  *[
    _type == "discount" &&
    locale == $locale &&
    enabled == true
  ] {
    ${discountFragment}
  }
`

export const siteFragment = groq`
  "publicLocales": *[
    _type == "generalSettings" &&
    public == true
  ][].locale,
  "settings": *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  },
  "identity": *[
    _type == "identitySettings" &&
    locale == $locale
  ][0] {
    ${identityFragment}
  },
  "cart": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${cartFragment}
  },
  "blogSettings": *[
    _type == "blogSettings" &&
    locale == $locale
  ][0] {
    ${blogSettingsFragment}
  },
  "productCounts": [{
    "slug": "all",
    "count": count(*[
      _type == "product" &&
      locale == $locale
    ]),
  }] + *[
    _type == "collection" &&
    locale == $locale
  ] {
    ${productCountsFragment}
  },
  "cookieConsent": *[
    _type == "cookieSettings" &&
    locale == $locale
  ][0] {
    ${cookieConsentFragment}
  },
  "header": *[
    _type == "headerSettings" &&
    locale == $locale
  ][0] {
    ${headerFragment}
  },
  "footer": *[
    _type == "footerSettings" &&
    locale == $locale
  ][0] {
    ${footerFragment}
  },
  "seo": *[
    _type == "seoSettings" &&
    locale == $locale
  ][0] {
    ${seoFragment}
  },
  "siteStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${siteStringsFragment}
  },
  "discounts": *[
    _type == "discount" &&
    locale == $locale &&
    enabled == true
  ] {
    ${discountFragment}
  },
`

export const siteQuery = groq`
  {
    ${siteFragment}
  }
`
