import groq from 'groq'

import { blogPostWithoutBodyFragment } from './blog'
import { modulesFragment } from './modules'
import { productFragment } from './product'
import { siteFragment } from './site'

export const allSlugsQuery = groq`
  *[
    _type == $type &&
    type != "template"
  ] {
    locale,
    "slug": slug.current,
  }
`

export const allProductSlugsQuery = groq`
  *[
    _type == "product" &&
    !wasDeleted &&
    !isDraft
  ] {
    locale,
    "slug": slug.current,
  }
`

export const sitemapPagesQuery = groq`
  *[
    _type == $type &&
    locale in $locales &&
    noIndex != true &&
    type != "template"
  ] {
    _type,
    locale,
    "slug": slug.current,
  }
`

export const sitemapStaticPagesQuery = groq`
  *[
    _type in $types &&
    locale in $locales &&
    noIndex != true
  ] {
    _type,
    locale,
  }
`

export const shopPageQuery = groq`
  {
    "page": *[
      _type == "shopPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      hasTransparentHeader,
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const shopPageModuleQuery = groq`
  *[
    _type == "shopPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const cartPageQuery = groq`
  {
    "page": *[
      _type == "cartPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      hasTransparentHeader,
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const cartPageModuleQuery = groq`
  *[
    _type == "cartPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const homePageQuery = groq`
  {
    "page": *[
      _type == "homePage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      hasTransparentHeader,
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const homePageModuleQuery = groq`
  *[
    _type == "homePage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const errorPageQuery = groq`
  {
    "page": *[
      _type == "errorPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const errorPageModuleQuery = groq`
  *[
    _type == "errorPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const signupPageQuery = groq`
  {
    "page": *[
      _type == "signupPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const signupPageModuleQuery = groq`
  *[
    _type == "signupPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const loginPageQuery = groq`
  {
    "page": *[
      _type == "loginPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const loginPageModuleQuery = groq`
  *[
    _type == "loginPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const passwordRecoveryPageQuery = groq`
  {
    "page": *[
      _type == "passwordRecoveryPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const passwordRecoveryPageModuleQuery = groq`
  *[
    _type == "passwordRecoveryPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const accountPageQuery = groq`
  {
    "page": *[
      _type == "accountPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const accountPageModuleQuery = groq`
  *[
    _type == "accountPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const accountAddressPageQuery = groq`
  {
    "page": *[
      _type == "accountAddressPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const accountAddressPageModuleQuery = groq`
  *[
    _type == "accountAddressPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const pageQuery = groq`
  {
    "page": *[
      _type == "page" &&
      slug.current in $pageSlugs &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${modulesFragment}
      },
      type,
      hasTransparentHeader,
      seo,
      noIndex,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const pageModuleQuery = groq`
  *[
    _type == "page" &&
    slug.current in $pageSlugs &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const blogPostPageQuery = groq`
  {
    "page": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current == $blogPostSlug &&
      locale == $blogLocale
    ]
      | order(_updatedAt desc)
    [0] {
      type,
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${modulesFragment}
      },
      "slug": slug.current,
      ${blogPostWithoutBodyFragment}
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogPostPageModuleQuery = groq`
  *[
    _type == "blogPost" &&
    type != "template" &&
    slug.current == $blogPostSlug &&
    locale == $blogLocale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const blogPageQuery = groq`
  {
    "page": *[
      _type == "blogPage" &&
      locale == $blogLocale
    ]
      | order(_updatedAt desc)
    [0] {
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${modulesFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogPageModuleQuery = groq`
  *[
    _type == "blogPage" &&
    locale == $blogLocale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const blogCategoryPageQuery = groq`
  {
    "page": *[
      _type == "blogCategory" &&
      slug.current == $blogCategorySlug &&
      locale == $blogLocale
    ]
      | order(_updatedAt desc)
    [0] {
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${modulesFragment}
      },
      "slug": slug.current,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogCategoryPageModuleQuery = groq`
  *[
    _type == "blogCategory" &&
    slug.current == $blogCategorySlug &&
    locale == $blogLocale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const blogAuthorPageQuery = groq`
  {
    "page": *[
      _type == "blogAuthor" &&
      slug.current == $blogAuthorSlug &&
      locale == $blogLocale
    ]
      | order(_updatedAt desc)
    [0] {
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${modulesFragment}
      },
      "slug": slug.current,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogAuthorPageModuleQuery = groq`
  *[
    _type == "blogAuthor" &&
    slug.current == $blogAuthorSlug &&
    locale == $blogLocale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const productPageQuery = groq`
  {
    "page": *[
      _type == "product" &&
      slug.current == $productSlug &&
      locale == $locale &&
      !wasDeleted &&
      !isDraft
    ]
      | order(_updatedAt desc)
    [0] {
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${modulesFragment}
      },
      privateModules[] {
        ${modulesFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const productPageModuleQuery = groq`
  *[
    _type == "product" &&
    slug.current == $productSlug &&
    locale == $locale &&
    !wasDeleted &&
    !isDraft
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`

export const collectionPageQuery = groq`
  {
    "page": *[
      _type == "collection" &&
      slug.current == $collectionSlug &&
      locale == $locale
    ] | order(_updatedAt desc)[0] {
      title,
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${modulesFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const collectionPageModuleQuery = groq`
  *[
    _type == "collection" &&
    slug.current == $collectionSlug &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${modulesFragment}
  }
`
