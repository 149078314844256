import groq from 'groq'

import { blocksFragment } from './blocks'
import { blogPostOptionsFragment, blogPostWithoutBodyFragment } from './blog'
import { cartFragment } from './cart'
import { complexContentFragment } from './content'
import { imageFragment } from './image'
import { productFragment, reviewProductFragment } from './product'
import {
  muxVideoFragment,
  videoFragment,
  vimeoVideoObjectFragment,
} from './video'

const gridModuleFragment = groq`
  size,
  style,
  noColumnGaps,
  noRowGaps,
  reverseSequence,
  columns[] {
    _key,
    style,
    sizes[] {
      breakpoint,
      width,
      justify,
      align,
      start,
    },
    blocks[] {
      ${blocksFragment}
    },
  },
`

const heroModuleFragment = groq`
  bgType,
  content[] {
    ${complexContentFragment}
  },
  contentPosition,
  photos {
    mobilePhoto {
      ${imageFragment}
    },
    desktopPhoto {
      ${imageFragment}
    },
  },
  ${muxVideoFragment}
  "vimeoVideo": *[
    _type == "vimeo.videoAsset" &&
    _id == ^.video.id
  ][0] {
    ${vimeoVideoObjectFragment}
  },
`

const dividerPhotoModuleFragment = groq`
  photo {
    ${imageFragment}
  },
`

const reviewsStringsFragment = groq`
  reviewsSingular,
  reviewsPlural,
  reviewsRatingBasedOnSingular,
  reviewsRatingBasedOnPlural,
  reviewsNoReviews,
  reviewsCarouselTitle,
  reviewsVerifiedBuyer,
  reviewsWriteReview,
  reviewsFormAuthorInputLabel,
  reviewsFormAuthorInputPlaceholder,
  reviewsFormAuthorMissing,
  reviewsFormEmailInputLabel,
  reviewsFormEmailInputPlaceholder,
  reviewsFormEmailMissing,
  reviewsFormReviewRatingInputLabel,
  reviewsFormReviewRatingMissing,
  reviewsFormReviewTitleInputLabel,
  reviewsFormReviewTitleInputPlaceholder,
  reviewsFormReviewTitleMissing,
  reviewsFormReviewMessageInputLabel,
  reviewsFormReviewMessageInputPlaceholder,
  reviewsFormReviewMessageMissing,
  reviewsFormSubmit,
  reviewsFormErrorMessage[] {
    ${complexContentFragment}
  },
  reviewsFormSuccessMessage[] {
    ${complexContentFragment}
  },
`

const reviewWidgetModuleFragment = groq`
  type,
  "reviewsStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${reviewsStringsFragment}
  },
`

const cartFormModuleFragment = groq`
  active,
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${cartFragment}
  },
`

const simpleMarqueeItemFragment = groq`
  text,
`

const photoMarqueeItemFragment = groq`
  "photo": {
    ${imageFragment}
  },
`

const productMarqueeItemFragment = groq`
  _id,
  "product": *[
    _type == "product" &&
    _id == ^._ref
  ][0] {
    ${productFragment}
  },
`

const marqueeModuleFragment = groq`
  speed,
  reverse,
  pausable,
  items[] {
    _key,
    _type,
    _type == 'simple' => {
      ${simpleMarqueeItemFragment}
    },
    _type == 'photo' => {
      ${photoMarqueeItemFragment}
    },
    _type == 'product' => {
      ${productMarqueeItemFragment}
    },
  },
`

const collectionStringsFragment = groq`
  collectionProducts,
  collectionProductCount,
  collectionSortLabel,
  collectionSortDescription,
  collectionShowFiltersLabel,
  collectionFilters,
  collectionRemoveFilter,
  collectionClearAllLabel,
  collectionClearFiltersLabel,
  collectionFilterResultsLabel,
  collectionFilterNoResultsLabel,
  collectionFilterModalClose,
  collectionFilterNoResults[] {
    ${complexContentFragment}
  },
`

const collectionGridFragment = groq`
  active,
  $collectionSlug != null => {
    "collection": *[
      _type == "collection" &&
      slug.current == $collectionSlug &&
      locale == $locale
    ][0] {
      title,
      "featuredProductIds": products[]->productID,
      "products": products[wasDeleted != true && isDraft != true]->{
        ${productFragment}
      },
    },
  },
  $collectionSlug == null => {
    "shop": *[
      _type == "shopPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      "featuredProductIds": featuredProducts[]->productID,
      "products": *[
        _type == "product" &&
        locale == $locale &&
        !wasDeleted &&
        !isDraft
      ]
        | order(title asc)
      {
        ${productFragment}
      },
    },
  },
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    paginationLimit,
    filter {
      isActive,
      groups[] {
        _key,
        title,
        "slug": slug.current,
        display,
        options[]->{
          type,
          title,
          "slug": slug.current,
          "color": color->color,
        },
      },
    },
    sort {
      isActive,
      options[] {
        type,
        title,
      },
    },
  },
  "collectionStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${collectionStringsFragment}
  },
`

const blogPostGridModuleFragment = groq`
  "posts": *[
    _type == "blogPost" &&
    type != "template" &&
    locale == $locale &&
    (!defined(^.author) || author->slug.current == ^.author->slug.current) &&
    (!defined(^.category) || categories[]->slug.current match ^.category->slug.current)
  ]
    | order(_createdAt desc)
    | order(date desc)
  {
    ${blogPostWithoutBodyFragment}
  },
  options {
    ${blogPostOptionsFragment}
  },
`

const blogPostHeaderModuleFragment = groq`
  $blogPostSlug != null => {
    "post": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      slug.current == $blogPostSlug
    ]
      | order(_updatedAt desc)
    [0] {
      ${blogPostWithoutBodyFragment}
    },
  },
  options {
    ${blogPostOptionsFragment}
  },
`

const blogCategoryPostGridGridModuleFragment = groq`
  $blogCategorySlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      categories[]->slug.current match $blogCategorySlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
  options {
    ${blogPostOptionsFragment}
  },
`

const blogAuthorPostGridModuleFragment = groq`
  $blogAuthorSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      locale == $locale &&
      author->slug.current == $blogAuthorSlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
  options {
    ${blogPostOptionsFragment}
  },
`

export const productHeroModuleFragment = groq`
  active,
  "product": *[
    _type == "product" &&
    locale == $locale &&
    slug.current == $productSlug &&
    !wasDeleted &&
    !isDraft
  ]
    | order(_updatedAt desc)
  [0] {
    ${productFragment}
  },
`

export const modulesFragment = groq`
  _key,
  _type,
  _type == 'grid' => {
    ${gridModuleFragment}
  },
  _type == 'hero' => {
    ${heroModuleFragment}
  },
  _type == 'dividerPhoto' => {
    ${dividerPhotoModuleFragment}
  },
  _type == 'reviewWidget' => {
    ${reviewWidgetModuleFragment}
  },
  _type == 'cartForm' => {
    ${cartFormModuleFragment}
  },
  _type == 'video' => {
    ${videoFragment}
  },
  _type == 'marquee' => {
    ${marqueeModuleFragment}
  },
  _type == 'collectionGrid' => {
    ${collectionGridFragment}
  },
  _type == 'blogPostGrid' => {
    ${blogPostGridModuleFragment}
  },
  _type == 'blogPostHeader' => {
    ${blogPostHeaderModuleFragment}
  },
  _type == 'blogCategoryPostGrid' => {
    ${blogCategoryPostGridGridModuleFragment}
  },
  _type == 'blogAuthorPostGrid' => {
    ${blogAuthorPostGridModuleFragment}
  },
  _type == 'productHero' => {
    ${productHeroModuleFragment}
  },
`
