import { useLiveQuery } from '@sanity/preview-kit'

import { SanityModule } from '@data/sanity/queries/types/modules'
import { Reviews } from '@lib/review'

import Modules from './modules'

interface PreviewModulesProps {
  modules: SanityModule[] | null
  moduleQuery: string
  moduleQueryParams?: Record<string, any>
  reviews?: Reviews
}

const PreviewModules = ({
  modules,
  moduleQuery,
  moduleQueryParams,
  reviews,
}: PreviewModulesProps) => {
  const [liveModules] = useLiveQuery(modules, moduleQuery, moduleQueryParams)

  return <Modules modules={liveModules} reviews={reviews} />
}

export default PreviewModules
