import groq from 'groq'

import { simpleContentFragment } from './content'

export const cartFragment = groq`
  storeUrl,
  taxRate,
  message,
  openInSeparatePage,
  showVatId,
  showComment,
  terms[] {
    ${simpleContentFragment}
  },
`
