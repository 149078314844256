import { type SanityClient } from '@sanity/client'

import {
  accountAddressPageQuery,
  accountPageQuery,
  errorPageQuery,
  homePageQuery,
  loginPageQuery,
  pageQuery,
  passwordRecoveryPageQuery,
  shopPageQuery,
  signupPageQuery,
  cartPageQuery,
  sitemapPagesQuery,
  allSlugsQuery,
  allProductSlugsQuery,
  sitemapStaticPagesQuery,
  collectionPageQuery,
  productPageQuery,
} from '@data/sanity/queries/page'
import {
  SanityAccountAddressPageQuery,
  SanityAccountPageQuery,
  SanityErrorPageQuery,
  SanityHomePageQuery,
  SanityLoginPageQuery,
  SanityPageQuery,
  SanityPasswordRecoveryPageQuery,
  SanityShopPageQuery,
  SanitySignupPageQuery,
  SanityCartPageQuery,
  SanityAllSlugs,
  SanityAllProductSlugs,
  SanitySitemapStaticPagesQuery,
  SanitySitemapPagesQuery,
  SanityCollectionPageQuery,
  SanityProductPageQuery,
} from '@data/sanity/queries/types/page'
import {
  SanityBlogAuthorSlugQueryParameter,
  SanityBlogCategorySlugQueryParameter,
  SanityBlogPostSlugQueryParameter,
  SanityCollectionSlugQueryParameter,
  SanityDocumentType,
  SanityLocaleQueryParameter,
  SanityLocalesQueryParameter,
  SanityPageSlugsQueryParameter,
  SanityProductSlugQueryParameter,
  SanityTypeQueryParameter,
  SanityTypesQueryParameter,
} from '@data/sanity/queries/types/parameters'
import { Locale } from '@lib/language'
import { PageType } from '@lib/routes'
import { fetchQuery } from './client'

export type SanityModuleQueryParameters = SanityBlogAuthorSlugQueryParameter &
  SanityBlogCategorySlugQueryParameter &
  SanityBlogPostSlugQueryParameter &
  SanityCollectionSlugQueryParameter &
  SanityProductSlugQueryParameter

export const defaultModuleQueryParameters: SanityModuleQueryParameters = {
  blogAuthorSlug: null,
  blogCategorySlug: null,
  blogPostSlug: null,
  collectionSlug: null,
  productSlug: null,
}

/**
 * Fetches all document slugs in all locales.
 */
export const getAllSlugs = async (
  sanityClient: SanityClient,
  type: SanityDocumentType
) =>
  fetchQuery<SanityAllSlugs, SanityTypeQueryParameter>(
    sanityClient,
    allSlugsQuery,
    {
      type,
    }
  )

/**
 * Fetches all product slugs in all locales.
 */
export const getAllProductSlugs = async (sanityClient: SanityClient) =>
  fetchQuery<SanityAllProductSlugs>(sanityClient, allProductSlugsQuery)

/**
 * Fetches pages for sitemap.
 */
export const getSitemapPages = async (
  sanityClient: SanityClient,
  locales: Locale[],
  type: SanityDocumentType
) =>
  fetchQuery<
    SanitySitemapPagesQuery,
    SanityTypeQueryParameter & SanityLocalesQueryParameter
  >(sanityClient, sitemapPagesQuery, {
    locales,
    type,
  })

/**
 * Fetches static pages for sitemap.
 */
export const getSitemapStaticPages = async (
  sanityClient: SanityClient,
  locales: Locale[]
) =>
  fetchQuery<
    SanitySitemapStaticPagesQuery,
    SanityLocalesQueryParameter & SanityTypesQueryParameter
  >(sanityClient, sitemapStaticPagesQuery, {
    locales,
    types: [
      PageType.HOME_PAGE,
      PageType.SHOP_PAGE,
      PageType.CART_PAGE,
      PageType.SIGNUP_PAGE,
      PageType.LOGIN_PAGE,
      PageType.PASSWORD_RECOVERY_PAGE,
      PageType.ACCOUNT_PAGE,
      PageType.ACCOUNT_ADDRESS_PAGE,
      PageType.BLOG_PAGE,
    ],
  })

/**
 * Fetches the shop index page.
 */
export const getShopIndexPage = async (
  sanityClient: SanityClient,
  locale: Locale
) =>
  fetchQuery<
    SanityShopPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, shopPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the cart page.
 */
export const getCartPage = async (sanityClient: SanityClient, locale: Locale) =>
  fetchQuery<
    SanityCartPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, cartPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the home page.
 */
export const getHomePage = async (sanityClient: SanityClient, locale: Locale) =>
  fetchQuery<
    SanityHomePageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, homePageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the error page.
 */
export const getErrorPage = async (
  sanityClient: SanityClient,
  locale: Locale
) =>
  fetchQuery<
    SanityErrorPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, errorPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches a specific page based on slug.
 */
export const getPage = async (
  sanityClient: SanityClient,
  locale: Locale,
  slug: string
) =>
  fetchQuery<
    SanityPageQuery,
    SanityLocaleQueryParameter &
      SanityPageSlugsQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, pageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    pageSlugs: [`/${slug}`, slug, `/${slug}/`],
  })

/**
 * Fetches the signup page.
 */
export const getSignupPage = async (
  sanityClient: SanityClient,
  locale: Locale
) =>
  fetchQuery<
    SanitySignupPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, signupPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the login page.
 */
export const getLoginPage = async (
  sanityClient: SanityClient,
  locale: Locale
) =>
  fetchQuery<
    SanityLoginPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, loginPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the password recovery page.
 */
export const getPasswordRecoveryPage = async (
  sanityClient: SanityClient,
  locale: Locale
) =>
  fetchQuery<
    SanityPasswordRecoveryPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, passwordRecoveryPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the account page.
 */
export const getAccountPage = async (
  sanityClient: SanityClient,
  locale: Locale
) =>
  fetchQuery<
    SanityAccountPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, accountPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the account address page.
 */
export const getAccountAddressPage = async (
  sanityClient: SanityClient,
  locale: Locale
) =>
  fetchQuery<
    SanityAccountAddressPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, accountAddressPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches a collection page based on its slug.
 */
export const getCollectionPage = async (
  sanityClient: SanityClient,
  locale: Locale,
  collectionSlug: string
) =>
  fetchQuery<
    SanityCollectionPageQuery,
    SanityLocaleQueryParameter &
      SanityCollectionSlugQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, collectionPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    collectionSlug,
  })

/**
 * Fetches product page data based on product slug.
 */
export const getProductPage = async (
  sanityClient: SanityClient,
  locale: Locale,
  productSlug: string
) =>
  fetchQuery<
    SanityProductPageQuery,
    SanityLocaleQueryParameter &
      SanityProductSlugQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, productPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    productSlug,
  })
